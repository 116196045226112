import { createSlice } from '@reduxjs/toolkit';
export const LANGUAGES={
  ENGLISH:'en', DANISH:'da', ARABIC:"ar",DEFAULT:'da',TURKISH:'tr',
  defaultObject: {"en":"","dk":""}
}
export const slice = createSlice({
  name: 'SettingsReducer',
  initialState: {
    selectedLanguage:LANGUAGES.DEFAULT,
  },
  reducers: {
    setSelectedLanguage : (state, action) => {
      state.selectedLanguage=action.payload;
    },
    resetSettingsReducer : (state) => {
      state.selectedLanguage=LANGUAGES.ENGLISH;
    },
  },
});

export const {
    setSelectedLanguage,
 } = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

export const selectedLanguageSelector = (state) => state.SettingsReducer.selectedLanguage;

export default slice.reducer;