import React from 'react'

const MailBlue = ({ className, iconColor = "#4A6DA7" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" className={className}>
            <path d="M9.33301 11.3333L13.2557 13.6526C15.5426 15.0047 16.4567 15.0047 18.7437 13.6526L22.6663 11.3333" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.68752 17.9675C2.77469 22.0548 2.81828 24.0985 4.32645 25.6124C5.83462 27.1263 7.93361 27.1791 12.1316 27.2845C14.7189 27.3496 17.2808 27.3496 19.8681 27.2845C24.0661 27.1791 26.1651 27.1263 27.6732 25.6124C29.1814 24.0985 29.225 22.0548 29.3122 17.9675C29.3402 16.6532 29.3402 15.3467 29.3122 14.0325C29.225 9.94511 29.1814 7.90142 27.6732 6.38751C26.1651 4.8736 24.0661 4.82086 19.8681 4.71538C17.2808 4.65038 14.7189 4.65037 12.1316 4.71538C7.93361 4.82084 5.83461 4.87358 4.32644 6.38749C2.81827 7.9014 2.77469 9.94509 2.68752 14.0325C2.6595 15.3467 2.6595 16.6532 2.68752 17.9675Z" stroke={iconColor} stroke-width="2" stroke-linejoin="round" />
        </svg>
    )
}

export default MailBlue