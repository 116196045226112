import { getLocalStorage } from "../components/shared/local_storage";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const appName=process.env.REACT_APP_NAME_LOCAL;
const digitalOceanPath = process.env.REACT_APP_DIGITAL_OCEAN;
// later on replace that with admin and remove test from path
const adminImagesPath = 'test/admin/';

const selectedLanguage = getLocalStorage('i18nextLng');

export const Config = {
  //   endPointPath:endPointPath,
  //   projectName:appName,
  //   tableOrderLink:tableOrderLink,
  //   path:path,
  secretPass: "XkhZG4fW2t2W",
  serverApiUrl: serverUrl + "api/",
  BASE_URL : "https://yaksport.dk",
  userToken: appName+"user-token",

  // serverUrlImages: `https://ys-prod.fra1.digitaloceanspaces.com/test/admin/images/hotels/medium/`,
  serverUrlImages: "https://ys-prod.fra1.digitaloceanspaces.com/" + adminImagesPath + "images/hotels/large/",
  hotelSportImage: digitalOceanPath + adminImagesPath +"images/hotels/large/",
  hotelReferenceImage: digitalOceanPath + adminImagesPath +"images/reference/",
  sportImage: digitalOceanPath +  adminImagesPath +"images/sports/large/",
  galleryImage: digitalOceanPath +  adminImagesPath +"images/galleries/large/",
  messageFile: digitalOceanPath + adminImagesPath + `message/attachments/`,
  //   serverUrlCategoryImages: serverUrl + 'images/categories/',
  //   serverUrlProductImages: serverUrl + 'public/images/products/',
  //   serverUrlUserImages: serverUrl + 'public/images/users/',
  //   adminApiTokenName: 'admin-login-token',
  //   roleName: 'role',
  //   email: 'email',
  //   verificationCode: 'verifiactionCode',

  //   userApiTokenName: 'user-login-token',
  //   googleApiKey: 'AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo',

  // Used in whole app
  distance_unit: "km",
  currency_symbol: "DKK",
  currency: "Kr.",
  searchFields: "searchField",
  //   appName: appName,

  pushTokenName: "device-push-token",

  // Here add langues and then add transaltion file for languages
  languages: [
    { shortName: "en", icon: "", longName: "English" },
    { shortName: "da", icon: "", longName: "Danish" },
  ],
  firstVisitDone: "firstVisitDone",

  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  getHotelImage: function (image) {
    // return image ? (this.serverUrlImages + image) : this.defaultImage;
    return "https://ugc.futurelearn.com/uploads/images/4d/c9/header_4dc9321b-f608-4196-9fb7-02f6c0029a5f.jpg";
  },

  vimeo: "https://player.vimeo.com/video/",
  youtube: "https://www.youtube.com/embed/",
  dailymotion: "http://www.dailymotion.com/embed/",
  // googleMapApiKey: "AIzaSyCvR1vK8n16g2u44S-PYZvtvJ5w9n00big",
  googleMapApiKey: "AIzaSyC_fcJ8FODEIbwm1fMmwRlkZ2QEMEip7BU",
  // googleMapApiKey: "AIzaSyCvR1vK8n16g2u44S-AIzaSyC_fcJ8FODEIbwm1fMmwRlkZ2QEMEip7BU",

  fn_date_yyyy_mm_dd : function (inputDate, year = new Date()?.getFullYear()) {
    if (!inputDate) {
      return {};
    }
    const dateWithYear = `${inputDate}, ${year}`;
    const parsedDate = new Date(dateWithYear);  
    const formattedYear = parsedDate?.getFullYear();
    const month = String(parsedDate?.getMonth() + 1)?.padStart(2, '0');
    const day = String(parsedDate?.getDate())?.padStart(2, '0');
    return `${formattedYear}-${month}-${day}`;
  },

  fnMultilingualDate : function (dateString) {
    if (!dateString) {
      return {};
    }
    const date = this.fn_date_yyyy_mm_dd(dateString);
    const incomingDate = new Date(date);
    return incomingDate instanceof Date ? incomingDate?.toLocaleDateString(selectedLanguage ?? LANGUAGES_CODE.Danish, {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    }) : '';
    // return incomingDate?.toLocaleDateString(selectedLanguage, {
    //   weekday: 'short',
    //   month: 'short',
    //   day: 'numeric',
    // });
  },

  __COOKIE__CONSENT__: "__cookie__consent__",

};

export const USER_MEMBERSHIP_TYPES = {
  premium : "premium",
}

export const KEYS = {
  lang: 'i18nextLng',
  region_lang : 'region_lang',
};

export const LANGUAGES_CODE = {
  English: 'en',
  Danish: 'da',
  German: 'de',
  Norwegian: 'no',
  Swedish:'sv'
};

export const TLD = {
  UK: 'uk',
  Denmark: 'dk',
  Germany: 'de',
  Norwegian: 'no',
  Sweden:'se'
};

const domainParts =window.location.hostname.split('.');

const PAGE_URLS = {
  BASE_URL: "https://yaksport.dk/",  
  TLD: domainParts[domainParts.length - 1],
};

export const COUNTRY_CODES = {
  denmark : '45',
  sweden : '46',
  uk : '44',
  norway : '47',
  germany : '49'
};

export default PAGE_URLS;