import React from 'react'

const PadelBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className={className}>
            <path d="M5 7L15 17" stroke={iconColor} stroke-width="1.5" stroke-linejoin="round" />
            <path d="M1.48802 17.1868C3.27193 16.0655 5.999 14.3875 6.40038 12.6847C6.52522 12.1551 6.24468 11.6415 6.00243 11.1542C4.83072 8.79703 4.80432 6.05244 6.54627 4.31272C9.58402 1.27886 16.1053 -0.504897 19.307 2.69226C22.505 5.88574 20.7222 12.4012 17.6842 15.4348C15.942 17.1746 13.1945 17.149 10.8339 15.9787C10.3457 15.7368 9.83136 15.4565 9.30087 15.5812C7.59557 15.982 5.91469 18.7046 4.79184 20.4859C3.57177 22.4214 -0.365547 18.3518 1.48802 17.1868Z" stroke={iconColor} stroke-width="1.5" stroke-linejoin="round" />
        </svg>
    )
}

export default PadelBlue