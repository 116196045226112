import React, { useEffect, useState } from "react";
import FootballBlue from "../../../assets/Icons/football__blue";
import { useSelector } from "react-redux";
import { selectedLanguageSelector } from "../../../redux/SettingsReducer";
import { getLocalStorage } from "../../../components/shared/local_storage/index";
import { Config, KEYS } from "../../../constant/Index";
const YAKText = ({
  text,
  icon,
  style = null,
  isTitle = false,
  maxChars = null,
  numberOfLines,
  showIcon = false,
  textStyle,
  className,
}) => {
  // const selectedLanguage = useSelector(selectedLanguageSelector);
  const selectedLanguage = getLocalStorage(KEYS.lang)  
  const [finalText, setFinalText] = useState();
  useEffect(() => {
    if (typeof text === "string") {
      setFinalText(text);
    } else if (typeof text === "object") {
      setFinalText(
        text?.[selectedLanguage] == undefined
          ? text?.["en"]
          : text?.[selectedLanguage]
      );
    }
  }, [text, selectedLanguage]);
  let tText =
    maxChars && finalText?.length > 0
      ? finalText?.substring(0, maxChars) +
        (finalText?.length > maxChars ? " ..." : "")
      : finalText;
  return (
    <span className={ `${className} ${textStyle ?? ""}`} style={style}>
      {showIcon && <FootballBlue />}
      {tText}
    </span>
  );
};
export default YAKText;