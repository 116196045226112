import React from 'react'

const FlightBlue = ({className, iconColor="#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" className={className}>
            <path d="M5.16712 11.9183L8.48859 9.89656C9.04597 9.55783 9.32467 9.38846 9.51705 9.15616C10.3496 8.15082 9.61644 6.66595 9.74059 5.49686C9.8691 4.28671 11.0231 2.63158 12.1864 2.11845C12.5444 1.96052 12.9551 1.96052 13.3131 2.11845C14.4764 2.63158 15.6304 4.28671 15.7589 5.49686C15.8831 6.66595 15.1499 8.15082 15.9825 9.15616C16.1748 9.38846 16.4535 9.55783 17.0109 9.89656L20.3327 11.9182C21.3493 12.5369 21.7498 13.1973 21.7498 14.4395C21.7498 15.1156 21.4506 15.2968 20.8473 15.1588L15.0225 13.8261L14.7609 16.1149C14.6661 16.9448 14.6187 17.3598 14.7558 17.7398C15.077 18.63 16.1673 19.3591 16.8332 20.0066C17.2013 20.3644 17.6029 21.3934 17.1833 21.8613C16.9242 22.1503 16.5033 21.9157 16.2137 21.803L13.425 20.7184C13.0916 20.5887 12.9248 20.5239 12.7498 20.5239C12.5747 20.5239 12.4079 20.5887 12.0745 20.7184L9.28584 21.803C8.99619 21.9157 8.57534 22.1503 8.31625 21.8613C7.8966 21.3934 8.29825 20.3644 8.66628 20.0066C9.3322 19.3591 10.4226 18.63 10.7437 17.7398C10.8808 17.3598 10.8334 16.9448 10.7386 16.1149L10.477 13.8261L4.65259 15.1587C4.04902 15.2968 3.74982 15.1155 3.75001 14.4391C3.75034 13.1971 4.1507 12.537 5.16712 11.9183Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default FlightBlue;  