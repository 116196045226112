import React from 'react'

const TennisBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className={className}>
            <path d="M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke={iconColor} stroke-width="1.5" />
            <path d="M4 4C7.99009 7.52068 8.0099 14.4618 4 18" stroke={iconColor} stroke-width="1.5" />
            <path d="M18 18C13.9901 14.4618 14.0099 7.52068 18 4" stroke={iconColor} stroke-width="1.5" />
        </svg>
    )
}

export default TennisBlue