import React from 'react'

const location__blue = ({ className, iconColor ="#4A6DA7" })  => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none" className={className}>
            <path d="M12.1177 20.367C11.6841 20.773 11.1044 21 10.5011 21C9.89785 21 9.31818 20.773 8.88449 20.367C4.91302 16.626 -0.409237 12.4469 2.18627 6.37966C3.58963 3.09916 6.95834 1 10.5011 1C14.0439 1 17.4126 3.09916 18.816 6.37966C21.4082 12.4393 16.099 16.6389 12.1177 20.367Z" stroke={iconColor} stroke-width="1.5" />
            <path d="M14 10C14 11.933 12.433 13.5 10.5 13.5C8.567 13.5 7 11.933 7 10C7 8.067 8.567 6.5 10.5 6.5C12.433 6.5 14 8.067 14 10Z" stroke={iconColor} stroke-width="1.5" />
        </svg>
    )
}

export default location__blue;