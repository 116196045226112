import React, { useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import "./HotelPageDrop.css";
import useOutsideClick from "../../../../../hooks/useOutsideClick";
import ArrowBlack from "../../../../../assets/Icons/arrow__black";
import useHomePageDataManager from "../../../../../hooks/useHomePageDataManager";
import { useTranslation } from "react-i18next";
import RoutesLink from "../../../../../constant/RoutesLink";
import DZText from "../../../../text/DZText";

const HotelPageDrop = ({ openHotelBar, setOpenHotelBar }) => {
  const {t} = useTranslation()
  const location = useLocation();
  const { slug } = useParams();
  const pathname = location.pathname;
  const hotelsLinksRef = useRef();
  const { pages } = useHomePageDataManager();
  const [showPages, setShowPages] = useState();
  useOutsideClick(hotelsLinksRef, () => setOpenHotelBar(false));
  
  const PageUrls = () => {
    return (
      <>
        {pages?.map((page, index) => (
          page?.is_active == 1 ?
            <Link to={page?.url} key={index} className={`drop__link ${slug === `${page?.url}` ? "active" : ""}`}>
              <DZText>{page?.title}</DZText>
            </Link>
            : <></>
        ))}
      </>
    );
  };

  return (
    <div
      className={`drop__down ${openHotelBar ? "active" : ""} `}
      ref={hotelsLinksRef}
    >
      <Link
        to="/"
        className={`drop__link ${pathname === "/" ? "acitve" : ""} `}
      >
        {t("navbar.front_page")}
      </Link>
      <Link
        to={RoutesLink.HOTELS}
        className={`drop__link ${pathname === RoutesLink.HOTELS ? "acitve" : ""} `}
      >
        {t("navbar.hotels")}
      </Link>
      <Link
        to={RoutesLink.ABOUT}
        className={`drop__link ${pathname === RoutesLink.ABOUT ? "acitve" : ""} `}
      >
        {t("navbar.about")}
      </Link>
      { pages?.length > 0 &&  <div
        className={`drop__div `}
        style={{
          flexDirection: "column",
          gap: "20px",
          cursor: "default",
          maxHeight: "fit-content",
        }}
      >
        <div
          onClick={() => setShowPages(!showPages)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          cursor: "pointer",
          width: "100%",
          }}
        >
          Info
          <ArrowBlack />
        </div>
        {showPages && <PageUrls />}
      </div>}
    </div>
  );
};
export default HotelPageDrop;
