import React from 'react'

const location__outline = ({
    className, iconColor = '#333333' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30" fill="none" className={className} >
            <path d="M18.3338 13.7448C18.3338 16.3221 16.2445 18.4115 13.6672 18.4115C11.0898 18.4115 9.00049 16.3221 9.00049 13.7448C9.00049 11.1675 11.0898 9.07812 13.6672 9.07812C16.2445 9.07812 18.3338 11.1675 18.3338 13.7448Z" stroke={iconColor} stroke-width="2" />
            <path d="M13.667 1.74463C20.1611 1.74463 25.667 7.12193 25.667 13.6457C25.667 20.2733 20.0714 24.9242 14.903 28.0869C14.5263 28.2995 14.1004 28.4113 13.667 28.4113C13.2336 28.4113 12.8077 28.2995 12.431 28.0869C7.27219 24.8934 1.66699 20.2962 1.66699 13.6457C1.66699 7.12193 7.17291 1.74463 13.667 1.74463Z" stroke={iconColor} stroke-width="2" />
        </svg >
    )
}

export default location__outline