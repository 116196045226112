import ReactRoutes from "./app/routes/ReactRoutes";
import SnackBar from "./app/components/shared/SnackBar/SnackBar";
import LoaderShow from "./app/components/shared/loaderShow/LoaderShow";

function App() {
  return (
    <>
      <LoaderShow />
      <SnackBar />
      <ReactRoutes />
    </>
  );
}
export default App;
