import React from 'react'

const calender__blue = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" className={className}>
            <path d="M18.8636 2.25V4.2M7.16357 2.25V4.2" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.007 12.975H13.0157M13.007 16.875H13.0157M16.9026 12.975H16.9113M9.11133 12.975H9.12008M9.11133 16.875H9.12008" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.72266 8.10004H21.2977" stroke="#4A6DA7" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.75 12.2372C3.75 7.98882 3.75 5.86464 4.97082 4.54484C6.19163 3.22504 8.1565 3.22504 12.0862 3.22504H13.9387C17.8685 3.22504 19.8334 3.22504 21.0542 4.54484C22.275 5.86464 22.275 7.98882 22.275 12.2372V12.7379C22.275 16.9862 22.275 19.1104 21.0542 20.4302C19.8334 21.75 17.8685 21.75 13.9387 21.75H12.0862C8.1565 21.75 6.19163 21.75 4.97082 20.4302C3.75 19.1104 3.75 16.9862 3.75 12.7379V12.2372Z" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.23926 8.10004H21.7893" stroke="#4A6DA7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default calender__blue