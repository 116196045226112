import React from 'react'

const AtletikBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none" className={className}>
            <path d="M14 2.5C14 3.32843 13.3284 4 12.5 4C11.6716 4 11 3.32843 11 2.5C11 1.67157 11.6716 1 12.5 1C13.3284 1 14 1.67157 14 2.5Z" stroke={iconColor} stroke-width="1.5" />
            <path d="M12 19L11.3359 16.3841C11.1161 15.5183 10.6615 14.7276 10.0207 14.0966L8.5 12.5991M3 9.15262C4 7.18287 5.53767 6.0415 9 6.00056M9 6.00056C9.21863 5.99798 9.54439 5.9972 9.86984 5.99726C10.3747 5.99734 10.6271 5.99738 10.8282 6.09134C11.0293 6.1853 11.2356 6.43176 11.6482 6.92469C11.7664 7.06592 11.8878 7.19246 12 7.27664M9 6.00056L7.7309 7.95877C7.03316 9.03538 6.68429 9.57368 6.67069 10.1389C6.66463 10.3906 6.70617 10.6412 6.79313 10.8776C6.98834 11.4082 7.49223 11.8051 8.5 12.5991M12 7.27664C13.1547 8.14255 14.9627 8.49128 17 6.19834M12 7.27664L8.5 12.5991" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 15.7303L1.67822 15.8916C3.40663 16.3028 5.20324 15.5164 6 14" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default AtletikBlue;