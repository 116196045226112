import React from 'react'

const CategoryBlue = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
            <path d="M21.5 17.5C21.5 18.9001 21.5 19.6002 21.2275 20.135C20.9878 20.6054 20.6054 20.9878 20.135 21.2275C19.6002 21.5 18.9001 21.5 17.5 21.5C16.0999 21.5 15.3998 21.5 14.865 21.2275C14.3946 20.9878 14.0122 20.6054 13.7725 20.135C13.5 19.6002 13.5 18.9001 13.5 17.5C13.5 16.0999 13.5 15.3998 13.7725 14.865C14.0122 14.3946 14.3946 14.0122 14.865 13.7725C15.3998 13.5 16.0999 13.5 17.5 13.5C18.9001 13.5 19.6002 13.5 20.135 13.7725C20.6054 14.0122 20.9878 14.3946 21.2275 14.865C21.5 15.3998 21.5 16.0999 21.5 17.5Z" stroke="#4A6DA7" stroke-width="1.5" />
            <path d="M21.5 9.5C21.5 10.9001 21.5 11.6002 21.2275 12.135C20.9878 12.6054 20.6054 12.9878 20.135 13.2275C19.6002 13.5 18.9001 13.5 17.5 13.5C16.0999 13.5 15.3998 13.5 14.865 13.2275C14.3946 12.9878 14.0122 12.6054 13.7725 12.135C13.5 11.6002 13.5 10.9001 13.5 9.5C13.5 8.09987 13.5 7.3998 13.7725 6.86502C14.0122 6.39462 14.3946 6.01217 14.865 5.77248C15.3998 5.5 16.0999 5.5 17.5 5.5C18.9001 5.5 19.6002 5.5 20.135 5.77248C20.6054 6.01217 20.9878 6.39462 21.2275 6.86502C21.5 7.3998 21.5 8.09987 21.5 9.5Z" stroke="#4A6DA7" stroke-width="1.5" />
            <path d="M13.5 17.5C13.5 18.9001 13.5 19.6002 13.2275 20.135C12.9878 20.6054 12.6054 20.9878 12.135 21.2275C11.6002 21.5 10.9001 21.5 9.5 21.5C8.09987 21.5 7.3998 21.5 6.86502 21.2275C6.39462 20.9878 6.01217 20.6054 5.77248 20.135C5.5 19.6002 5.5 18.9001 5.5 17.5C5.5 16.0999 5.5 15.3998 5.77248 14.865C6.01217 14.3946 6.39462 14.0122 6.86502 13.7725C7.3998 13.5 8.09987 13.5 9.5 13.5C10.9001 13.5 11.6002 13.5 12.135 13.7725C12.6054 14.0122 12.9878 14.3946 13.2275 14.865C13.5 15.3998 13.5 16.0999 13.5 17.5Z" stroke="#4A6DA7" stroke-width="1.5" />
            <path d="M9.5 5.5C9.5 6.90013 9.5 7.6002 9.22752 8.13497C8.98783 8.60538 8.60538 8.98783 8.13498 9.22751C7.6002 9.5 6.90013 9.5 5.5 9.5C4.09987 9.5 3.3998 9.5 2.86502 9.22751C2.39462 8.98783 2.01217 8.60538 1.77248 8.13497C1.5 7.60019 1.5 6.90013 1.5 5.5C1.5 4.09987 1.5 3.3998 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.3998 1.5 4.09987 1.5 5.5 1.5C6.90013 1.5 7.6002 1.5 8.13498 1.77248C8.60538 2.01217 8.98783 2.39462 9.22752 2.86502C9.5 3.3998 9.5 4.09987 9.5 5.5Z" stroke="#4A6DA7" stroke-width="1.5" />
        </svg>
    )
}

export default CategoryBlue