import React, { useEffect, useRef, useState } from "react";
import LocationBlue from "../../../../assets/Icons/location__blue.jsx";
import CategoryBlue from "../../../../assets/Icons/category__blue.jsx";
import DateBlue from "../../../../assets/Icons/calender__blue.jsx";
import UserMultiple from "../../../../assets/Icons/user__multiple.jsx";
import FlightBlue from "../../../../assets/Icons/flight__blue.jsx";
import SearchWhite from "../../../../assets/Icons/search__white.jsx";
import GuestsPopup from "./Components/GuestsPopup/GuestsPopup.jsx";
import FlightPopup from "./Components/FlightPopup/FlightPopup.jsx";
import LocationPopup from "./Components/LocationPopup/LocationPopup.jsx";
import CategoryPopup from "./Components/CategoryPopup/CategoryPopup.jsx";
import DateCalender from "./Components/DateCalender/DateCalender.jsx";
import "./SearchFilter.css";
import YAKText from "../../../shared/YakText/YAKText.js";
import SportIcon from "../../../shared/SportIcon/SportIcon.js";
import useSearchFilter from "../../../../hooks/searchFilter/useSearchFilter.js";
import useHomePageDataManager from "../../../../hooks/useHomePageDataManager.js";
import { useTranslation } from "react-i18next";
import { getLocalStorage } from "../../../shared/local_storage/index.js";
import useOutsideClick from "../../../../hooks/useOutsideClick.js";
import DZText from "../../../text/DZText.js";
import { Config } from "../../../../constant/Index.js";
import { t } from "i18next";
const SearchFilter = ({ NavSearchFilter }) => {
  const {
    airportData,
    activeFilter,
    searchFields,
    setSearchFields,
    handleClosePopup,
    handleFilter,
    handleSearch,
    setActiveFilter,
    setAirportData,
  } = useSearchFilter();
  const {
    airportsByCountryIds,
    airportsByCityIds,
    countries,
    regionAirports,
    sports,
  } = useHomePageDataManager();
  const searchRef = useRef();

  useOutsideClick(searchRef, handleClosePopup);



  useEffect(() => {
    const bAirports = searchFields?.countryId
      ? Object.values(airportsByCountryIds[searchFields?.countryId] || {})
      : Object.values(airportsByCityIds[searchFields?.cityId] || {});
    const commonAirports = regionAirports?.filter((rAirport) => {
      return bAirports?.some((bAirport) =>
        rAirport && bAirport ? rAirport.id === bAirport.id : false
      );
    });
    if (regionAirports?.length > 0) {
      if (commonAirports?.length <= 0) {
        setSearchFields((prev) => ({
          ...prev,
          flight: null,
          flightId: null,
        }));
      }
    }
    setAirportData(commonAirports);
  }, [
    airportsByCountryIds,
    airportsByCityIds,
    searchFields?.countryId,
    searchFields?.cityId,
  ]);

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  function formatDate(inputDate) {
    if (!inputDate) {
      return t("search_filter.invalid_date");
    }
    const dateObject = new Date(inputDate);
    if (isNaN(dateObject?.getTime())) {
      return t("search_filter.invalid_date");
    }
    const options = { weekday: "short", day: "numeric", month: "short" };
    const formattedDate = dateObject?.toLocaleDateString("en", options);
    return formattedDate;
  };

  const defaultFromDate = addDays(new Date(), 20);
  const defaultToDate = addDays(new Date(), 30);
  useEffect(() => {
    if (!searchFields?.date || searchFields?.date.length === 0) {

      setSearchFields({
        ...searchFields,
        date: [defaultFromDate, defaultToDate],
        from_date: formatDate(new Date(defaultFromDate)?.toISOString()?.split("T")[0]),
        to_date: formatDate(new Date(defaultToDate)?.toISOString()?.split("T")[0]),
      })
    }
  }, [searchFields])
  return (
    <div
      ref={searchRef}
      className={`${NavSearchFilter
        ? "d__flex align__center gap__8 p__08  radi__48 b__1__primary20 nav__searchFilter"
        : "hero__search"
        }`}
    >
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "location" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <LocationPopup
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            handleClosePopup={handleClosePopup}
            setActiveFilter={setActiveFilter}
          />
        }
        popupIcon={
          <LocationBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"location"}
        value={
          searchFields?.country ? (
            <DZText>{searchFields?.country}</DZText>
          ) : searchFields?.city ? (
            searchFields?.city
          ) : (
            <DZText>{"search_filter.select_location"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "category" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <CategoryPopup
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            handleClosePopup={handleClosePopup}
          />
        }
        popupIcon={
          searchFields?.sport?.en ? (
            <SportIcon
              sport={searchFields?.sport?.en}
              iconColor="#4A6DA7"
              className={`${NavSearchFilter ? "w__16 h__16" : "w__24 h__24"}`}
            />
          ) : (
            <CategoryBlue
              className={`${NavSearchFilter ? "w__16 h__16" : "w__24 h__24"}`}
            />
          )
        }
        type={"category"}
        value={<DZText>{searchFields?.sport ? searchFields?.sport : 'search_filter.select_sport'}</DZText>}
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "guests" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <GuestsPopup
            values={searchFields?.guests}
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            onClose={handleClosePopup}
          />
        }
        popupIcon={
          <UserMultiple className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"guests"}
        value={searchFields?.guests}
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "date" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <DateCalender
            setSearchFields={setSearchFields}
            searchFields={searchFields}
            activeFilter={activeFilter}
            date={searchFields.date}
            setActiveFilter={setActiveFilter}
          />
        }
        popupIcon={
          <DateBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"date"}
        value={
          searchFields?.from_date || defaultFromDate || searchFields?.to_date || defaultToDate ? (
            `${Config.fnMultilingualDate(searchFields?.from_date || defaultFromDate)} - ${Config?.fnMultilingualDate(searchFields?.to_date || defaultToDate)}`
          ) : (
            <DZText>{"search_filter.date"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <SearchSection
        handleFilter={handleFilter}
        activeFilter={activeFilter == "flight" ? "search__tabActive" : ""}
        searchFields={searchFields}
        setSearchFields={setSearchFields}
        handleClosePopup={handleClosePopup}
        popupComponent={
          <FlightPopup
            activeFilter={activeFilter}
            airportData={airportData}
            searchFields={searchFields}
            setSearchFields={setSearchFields}
            setActiveFilter={setActiveFilter}
          />
        }
        popupIcon={
          <FlightBlue className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        }
        type={"flight"}
        value={
          searchFields?.flight ? (
            searchFields?.flight
          ) : (
            <DZText>{"search_filter.select_airport"}</DZText>
          )
        }
        NavSearchFilter={NavSearchFilter}
      />
      <div
        className={` ${NavSearchFilter
          ? "back__orange__100 d__flex align__center justify__center gap__8 pl__8 pr__8 pt__8 pb__8 radi__20 cursor__p b__1__transparent"
          : "search__tab align__center justify__center search__btn"
          }`}
        onClick={handleSearch}
      >
        <SearchWhite className={`${NavSearchFilter ? "w__16 h__16" : ""}`} />
        <DZText className={NavSearchFilter ? "d__none" : "poppins__18"}>
          {"search_filter.search"}
        </DZText>
      </div>
    </div>
  );
};

const SearchSection = ({
  handleFilter,
  activeFilter,
  popupComponent,
  popupIcon,
  type,
  value,
  NavSearchFilter,
}) => {
  return (
    <div
      className={`${NavSearchFilter
        ? "position__rel"
        : type == "location"
          ? "search__tab w__full align__center"
          : "search__tab align__center justify__center"
        } ${activeFilter} `}
      style={{ padding: 0, margin: 0 }}
    >
      <div
        className={` ${NavSearchFilter
          ? "back__primary__05 d__flex align__center justify__center gap__8 pl__12 pr__12 pt__8 pb__8 radi__20 cursor__p hover-border__blue transition__03 b__1__transparent"
          : "w__full d__flex gap__8 align__center"
          }`}
        onClick={() => handleFilter(type)}
        style={{ width: '100%', height: '100%', borderRadius: '50px', margin: !NavSearchFilter && '10px 0px', padding: !NavSearchFilter ? '0px 14px' : '8px 14px' }}
      >
        <span className={` ${NavSearchFilter ? "lg-d__none" : ""}`}>
          {popupIcon}
        </span>
        <span
          className={` text__capitalize max-sm:w-full max-sm:truncate ${NavSearchFilter ? "solid__black__80 fs__12" : "poppins__18"
            }`}
        >
          {value}
        </span>
      </div>
      {popupComponent}
    </div>
  );
};
export default SearchFilter;
