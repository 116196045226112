"use client";
import { useState, useRef, useEffect } from "react";
import { useSnackBarManager } from "../useSnackBarManager";
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "../../components/shared/local_storage";
import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../redux/LoaderReducer";
import { getLocalStorage } from "../../components/shared/local_storage";
export default function useSearchFilter() {
  const navigate = useNavigate();
  const localStorageSearchFilter = JSON.parse(getLocalStorage("searchField"))
  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();
  const popupRef = useRef(null);
  const [airportData, setAirportData] = useState([]);
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState(false);
  const [searchFields, setSearchFields] = useState(localStorageSearchFilter || {
    country: "",
    countryId: undefined,
    city: undefined,
    cityId: undefined,
    sport: undefined,
    sportId: 1,
    date: undefined,
    guests: 8,
    flight: "",
    flightId: 0,
    from_date: "",
    to_date: "",
  });

  const handleClosePopup = () => {
    setActiveFilter("");
  };


  const handleFilter = (filter) => {
    setActiveFilter((prevFilter) => (prevFilter === filter ? "" : filter));
  };

  const convertTimestampsToDates = (timestamps) => {
    if (timestamps?.length !== 2) {
      throw new Error("Invalid input: Expected an array with two timestamps");
    }
    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const startDate = formatDate(timestamps[0]);
    const endDate = formatDate(timestamps[1]);
    return [startDate, endDate];
  };

  const constructQueryString = (params) => {
    const queryString = Object?.keys(params)
      ?.map((key) => {
        if (Array.isArray(params[key])) {
          return params[key]?.map((item) => `${key}[]=${item}`)?.join("&");
        }
        return `${key}=${params[key]}`;
      })
      ?.join("&");
    return queryString;
  };

  const handleSearch = () => {
    const {
      countryId,
      cityId,
      sportId,
      from_date,
      to_date,
      guests,
      flightId,
      date,
      country,
      city,
    } = searchFields;
    console.log('from date', from_date, 'to date', to_date, 'countryId', countryId, 'cityId', cityId, 'sportId', sportId, 'guests', guests, 'date', date, 'country', country);
    if (
      (!searchFields?.country && !searchFields?.countryId) &&
      (!searchFields?.city && !searchFields?.cityId)
    ) {
      fnShowSnackBar(t("search_filter.select_country"), true);
      return;
    } else if (!searchFields?.sport || !searchFields?.sportId) {
      fnShowSnackBar(t("search_filter.select_sport"), true);
      return;
    } else if (!searchFields?.date) {
      fnShowSnackBar(t("search_filter.select_date"), true);
      return;
    } else {
      const convertedDate = convertTimestampsToDates(date);
      console.log(searchFields, "searchFields")
      setLocalStorage("searchField", JSON.stringify(searchFields));
      const data = {
        country_id: countryId,
        city_id: cityId,
        country: country,
        city: city,
        sports: sportId,
        no_persons: guests,
        daterange: `${from_date} - ${to_date}`,
        from_date: convertedDate?.[0],
        to_date: convertedDate?.[1],
        flight_id: flightId,
      };
      const convertedFromDate = Config.fn_date_yyyy_mm_dd(from_date);
      const convertedToDate = Config.fn_date_yyyy_mm_dd(to_date);
      const changeDataFormat = constructQueryString({
        sport_ids: [data?.sports],
        city_ids: data?.city_id ? [data?.city_id] : [],
        from_date: convertedFromDate,
        to_date: convertedToDate,
        country_id: data?.country_id ? [data?.country_id] : [],
        no_persons: guests
      });

      navigate(
        // `/hotels?country_id=${data.country_id}&city_id=${data.city_id}&sports=${data.sports}&no_persons=${data.no_persons}&from_date=${data.from_date}&to_date=${data.to_date}&airport=${data.flight_id}&daterange=${data.daterange}&country_name=${data.country?.en}&city_name=${data.city?.en}`
        // `city_ids=${data?.city_id}&sport_ids=${data?.sports}&from_date=${data?.from_date}&to_date=${data?.to_date}`
        `hotels?${changeDataFormat}`
      );
      setTimeout(() => {
        dispatch(hideLoader());
      }, 1000);
    }
  };

  return {
    airportData,
    activeFilter,
    setActiveFilter,
    searchFields,
    setSearchFields,
    popupRef,
    handleClosePopup,
    handleFilter,
    handleSearch,
    setAirportData,
  };
}