import React from "react";

const MailOutline = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      className={className}
    >
      <path
        d="M8.33301 8.41113L12.2557 10.7304C14.5426 12.0825 15.4567 12.0825 17.7437 10.7304L21.6663 8.41113"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.68752 15.0455C1.77469 19.1328 1.81828 21.1765 3.32645 22.6904C4.83462 24.2043 6.93361 24.2571 11.1316 24.3626C13.7189 24.4276 16.2808 24.4276 18.8681 24.3626C23.0661 24.2571 25.1651 24.2043 26.6732 22.6904C28.1814 21.1765 28.225 19.1328 28.3122 15.0455C28.3402 13.7312 28.3402 12.4247 28.3122 11.1105C28.225 7.02311 28.1814 4.97942 26.6732 3.46551C25.1651 1.9516 23.0661 1.89886 18.8681 1.79339C16.2808 1.72838 13.7189 1.72838 11.1316 1.79338C6.93361 1.89885 4.83461 1.95158 3.32644 3.46549C1.81827 4.9794 1.77469 7.02309 1.68752 11.1105C1.6595 12.4247 1.6595 13.7312 1.68752 15.0455Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MailOutline;
