import React from 'react'

const BudgetIcon = ({className, iconColor="#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className={className}>
            <path d="M13 16.845C17.4183 16.845 21 13.2632 21 8.84497C21 4.42669 17.4183 0.844971 13 0.844971C8.58172 0.844971 5 4.42669 5 8.84497C5 13.2632 8.58172 16.845 13 16.845Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" />
            <path d="M12.1669 19.8139C11.063 20.4689 9.77419 20.845 8.3975 20.845C4.31197 20.845 1 17.533 1 13.4475C1 12.0708 1.37607 10.7819 2.03107 9.67804" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" />
        </svg>
    )
}

export default BudgetIcon