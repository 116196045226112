import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorage } from '../shared/local_storage';
import { KEYS } from '../../constant/Index';

const DZText = ({ children, className, style, upperCase, onClick, title = false }) => {
    
    const { t } = useTranslation();
    const selectedLanguage = getLocalStorage(KEYS.lang);
    const showText = typeof (children) == 'object' ? (children?.[selectedLanguage] ?? '') : t(children);
    const mergedStyle = upperCase ? { ...style, textTransform: 'uppercase' } : style;

    return (
        <span title={ title && showText} className={className} onClick={onClick} style={mergedStyle}>
            {showText}
        </span>
    )
}

export default React.memo(DZText);