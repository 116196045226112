import YAKText from "../../../../../shared/YakText/YAKText";
import useHomePageDataManager from "../../../../../../hooks/useHomePageDataManager";
import React from "react";
import "./locationPopup.css";
import DZText from "../../../../../text/DZText";
import {getLocalStorage} from "../../../../../shared/local_storage"
import {KEYS} from '../../../../../../constant/Index'
const LocationPopup = ({
  activeFilter,
  setSearchFields,
  searchFields,
  handleClosePopup,
  inputFiled,
  setInquiryFirstData,
}) => {
  const { countries, findCountryHotelsCount, findCityHotelsCount } =
    useHomePageDataManager();
    const selectedLanguage = getLocalStorage(KEYS.lang);
  const handleSelectCountry = (value, id) => {
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields) => ({
        ...prevSearchFields,
        country: value,
        countryId: id,
        city: undefined,
        cityId: undefined,
      }));
    } else {
      setSearchFields({
        ...searchFields,
        country: value,
        countryId: id,
        city: undefined,
        cityId: undefined,
      });
    }
  };
  const handleSelectCities = (value, id) => {
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields) => ({
        ...prevSearchFields,
        city: value,
        cityId: id,
        country: undefined,
        countryId: undefined,
      }));
    } else {
      setSearchFields({
        ...searchFields,
        country: undefined,
        countryId: undefined,
        city: value,
        cityId: id,
      });
    }
  };
  // console.log(searchFields, "searchFields")
  return (
    (activeFilter == "location" || inputFiled == "location") && (
      <div
        className={`search__popup  ${
          inputFiled ? "min__w__300 overflow__s h__300" : "min__w__360"
        }  ${
          activeFilter == "location" || inputFiled == "location"
            ? "active__popup"
            : ""
        }`}
      >
        {countries?.map((country) => {
          return (
            <>
              <span
                className="poppins__18 cursor__p d__flex align__center gap__12"
                onClick={() => {
                  handleSelectCountry(country?.name?.[selectedLanguage], country?.id);
                }}
              >
                <DZText
                  className={`${
                    searchFields?.countryId == country?.id &&
                    "fw__500 solid__primary__100"
                  }`}
                  onClick={() => {
                    handleClosePopup();
                  }}
                >
                  {country?.name}
                </DZText>
                <DZText
                  onClick={() => {
                    handleClosePopup();
                  }}
                  className={
                    "back__black__10 d__flex align__center justify__center w__fit w__28 h__28 radi__30"
                  }
                >
                  {findCountryHotelsCount(country?.id)}
                </DZText>
              </span>
              {!inputFiled &&
                country?.cities?.map((city ,index) => {
                  return (
                    <span key={index}>
                      <span
                        className="poppins__18 cursor__p d__flex align__center gap__12"
                        onClick={() => {
                          handleClosePopup();
                          handleSelectCities(city?.name?.[selectedLanguage], city?.id);
                        }}
                      >
                        <DZText
                          className={`pl__12 ${
                            searchFields?.cityId == city?.id &&
                            "fw__500 solid__primary__100"
                          }`}
                          onClick={() => {
                            handleClosePopup();
                            handleSelectCities(city?.name?.[selectedLanguage], city?.id);
                          }}
                        >
                          {city?.name}
                        </DZText>{" "}
                        <DZText
                       onClick={() => {
                        handleClosePopup();
                        handleSelectCities(city?.name?.[selectedLanguage], city?.id);
                      }}
                          className={
                            "back__black__10 d__flex align__center justify__center w__fit w__28 h__28 radi__30"
                          }
                        >
                          {findCityHotelsCount(city?.id)}
                        </DZText>
                      </span>
                    </span>
                  );
                })}
            </>
          );
        })}
      </div>
    )
  );
};
export default LocationPopup;
