import React, { useEffect, useRef } from "react";
import useSearchFilter from "../../../../../../hooks/searchFilter/useSearchFilter";
const FlightPopup = ({
  activeFilter,
  airportData,
  searchFields,
  setSearchFields,
  setActiveFilter,
  inputFiled,
  setInquiryFirstData,
  handleClosePopup,
}) => {
  const handleFlight = (value, name) => {
    if (inputFiled) {
      setInquiryFirstData((prevDate) => ({
        ...prevDate,
        flightId: value,
        flight: name,
      }));
      handleClosePopup();
    } else {
      setSearchFields({
        ...searchFields,
        flight: name,
        flightId: value,
      });
      setActiveFilter("");
    }
  };
    if (!airportData) return null;
  return (
    <div
      className={`search__popup min__w__200
      ${inputFiled ? "t__70" : ""}
      ${
        activeFilter == "flight" || inputFiled == "flight"
          ? "active__popup"
          : ""
      }`}
    >
      {Object?.keys(airportData)?.map((key) => {
        return (
          <span
            key={key}
            className="poppins__18 cursor__p"
            onClick={() =>
              handleFlight(airportData[key].id, airportData[key].name)
            }
          >
            {airportData[key].name}
          </span>
        );
      })}
    </div>
  );
};

export default FlightPopup;
