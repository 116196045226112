import React from 'react'

const club__blue = ({ className, iconColor = "#4A6DA7" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30" fill="none" className={className}>
            <path d="M13 1.66667C10.0545 1.66667 7.66667 4.1372 7.66667 7.18475C7.66667 8.18984 7.92638 9.13216 8.38017 9.94379C4.94413 9.02878 1 11.6816 1 15.4619C1 19.7344 5.18588 21.6988 8.74682 20.7691C9.8983 20.4685 10.474 20.3182 10.6372 20.4948C11.2613 21.1705 9.46148 24.5437 9.1369 25.2105C8.52569 26.466 8.22008 27.0938 8.4569 27.4857C9.01951 28.4167 16.7494 28.7991 17.5431 27.4857C17.7799 27.0938 17.4743 26.466 16.8631 25.2105C16.5385 24.5437 14.7387 21.1705 15.3628 20.4948C15.5259 20.3182 16.1017 20.4685 17.2532 20.7691C20.7984 21.6947 25 19.6916 25 15.4619C25 11.7081 21.0443 9.03185 17.6198 9.94379C18.0736 9.13216 18.3333 8.18984 18.3333 7.18475C18.3333 4.1372 15.9455 1.66667 13 1.66667Z" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default club__blue