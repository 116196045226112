import React, { useRef, useState } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import "./TabBar.css";
import UserWhite from "../../../../../assets/Icons/user__white";
import CrossImg from "../../../../../assets/Icons/crossIcon.png";
import ArrowBlack from "../../../../../assets/Icons/arrow__black";
import PhoneOutline from "../../../../../assets/Icons/phone__outline";
import MailOutline from "../../../../../assets/Icons/mail__outline";
import useHomePageDataManager from "../../../../../hooks/useHomePageDataManager";
import { useTranslation } from "react-i18next";
import RoutesLink from "../../../../../constant/RoutesLink";
import DZText from "../../../../text/DZText";
import useDisableScroll from "../../../../../hooks/useDisableScroll";
import { getLocalStorage } from "../../../local_storage";
import { Config } from "../../../../../constant/Index";
import { UserIcon } from "../../../../../assets/Icons/AllIcons";
const TabBar = ({
  handleTabClose,
  handleInquiryModel,
  handleLoginModel,
  openTabBar,
  handleLogout,
  pathname,
  fnOnClickProfileTab,
}) => {
  const { t } = useTranslation();
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const { slug } = useParams();
  const profileModalRef = useRef(null);
  const [openDropDiv, setOpenDropDiv] = useState(false);
  const { pages } = useHomePageDataManager();
  const PageUrls = () => {
    return (
      <>
        {pages?.map((page, index) => {
          if (page.is_active == 1) {
            return (
              <NavLink
                to={page?.url}
                key={index}
                className={`drop__link`}
                activeClassName="active"
                onClick={() => {
                  setOpenDropDiv(false);
                  handleTabClose();
                }}
              >
                {/* {page?.title?.en} */}
                <DZText>{page?.title}</DZText>
              </NavLink>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };
  useDisableScroll(openTabBar);
  return (
    <>
      <div className="tab__bar">
        <div className="tabBar__header">
          <Link className="tabBar__header" to={RoutesLink.HOME}>
            <img src="/images/nav__logo.png" alt="" />
          </Link>
          <div className="left__side d__flex align__center gap-[8px]">
            <span
              onClick={() => {
                handleInquiryModel();
                handleTabClose();
              }}
              className="fs__14 solid__black__80 cursor__p"
            >
              {t("navbar.get_an_offer")}
            </span>
            {!getLocalStorage(Config.userToken) && (
              <span
                onClick={() => {
                  handleLoginModel();
                  handleTabClose();
                }}
                className="d__flex align__center gap__8 fs__14 solid__black__80 pr__12 pl__12 pt__8 pb__8 radi__20 opacity__white__100 back__orange__100 cursor__p"
              >
                <UserWhite />
                {t("navbar.sign_in")}
              </span>
            )}
            {getLocalStorage(Config.userToken) && (
              <div className=" relative">
                <div
                  onClick={() => setOpenProfileModal(!openProfileModal)}
                  className="rounded-full py-[8px] px-[16px] cursor__p !bg-[#4A6DA7]"
                >
                  <UserIcon iconColor={"white"} />
                </div>
                <div
                  className={`drop__down  ${
                    openProfileModal ? "active !top-[150%] !right-[-70px]" : ""
                  } `}
                  ref={profileModalRef}
                >
                  <Link
                    to={RoutesLink.LEADS_PAGE}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.LEADS_PAGE ? "acitve" : ""
                    } `}
                  >
                    {t("profile.my_leads")}
                  </Link>
                  <Link
                    to={RoutesLink.LEADS_OFFERS_PAGE}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.LEADS_OFFERS_PAGE ? "acitve" : ""
                    } `}
                  >
                    {t("profile.leads_offer")}
                  </Link>
                  <Link
                    to={RoutesLink.LEADS_CONTRACT_PAGE}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.LEADS_CONTRACT_PAGE
                        ? "acitve"
                        : ""
                    } `}
                  >
                    {t("profile.lead_offer_contract")}
                  </Link>

                  <Link
                    to={RoutesLink.MESSAGE_PAGE}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.MESSAGE_PAGE ? "acitve" : ""
                    } `}
                  >
                    {t("profile.messages")}
                  </Link>

                  <Link
                    to={RoutesLink.FAQS}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.PROFILE_PAGE ? "acitve" : ""
                    } `}
                  >
                    {t("profile.faqs")}
                  </Link>

                  <Link
                    to={RoutesLink.PROFILE_PAGE}
                    onClick={fnOnClickProfileTab}
                    className={`drop__link ${
                      pathname === RoutesLink.PROFILE_PAGE ? "acitve" : ""
                    } `}
                  >
                    {t("profile.profile")}
                  </Link>

                  <span
                    onClick={() => handleLogout()}
                    className="cursor-pointer drop__link"
                  >
                    Logout
                  </span>
                </div>
              </div>
            )}
            <img
              src={CrossImg}
              alt=""
              className="cursor__p"
              onClick={handleTabClose}
            />
          </div>
        </div>
        <div className="bar__links">
          <NavLink
            to={RoutesLink.HOME}
            className="link"
            activeClassName="active"
            onClick={() => handleTabClose(false)}
          >
            {t("navbar.front_page")}
          </NavLink>
          <NavLink
            to={RoutesLink.HOTELS}
            className="link"
            activeClassName="active"
            onClick={() => handleTabClose(false)}
          >
            {t("navbar.hotels")}
          </NavLink>
          <NavLink
            to={RoutesLink.ABOUT}
            className="link"
            activeClassName="active"
            onClick={() => handleTabClose(false)}
          >
            {t("navbar.about")}
          </NavLink>
          <div
            className={`drop__div  openDrop__div cursor-pointer `}
            style={{
              flexDirection: "column",
              gap: "20px",
              cursor: "default",
              overflowY: "scroll",
              maxHeight: "240px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenDropDiv(!openDropDiv);
              }}
            >
              Info
              <ArrowBlack className={"cursor-pointer "} />
            </div>
            {openDropDiv && <PageUrls />}
          </div>
        </div>
        <div className="contact__details">
          <div className="contact__div">
            <PhoneOutline />
            <span className="link">78 70 64 45</span>
          </div>
          <div className="contact__div">
            <MailOutline />
            <span className="link">info@yaksport.dk</span>
          </div>
          <div className="contact__div">
            <PhoneOutline />
            <span className="link">Gunnar Clausens Vej 34B, 8260 Viby J</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default TabBar;
