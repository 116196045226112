import React from 'react'

const ArrowBlack = ({className, style, iconColor="#333", onMouseOver}) => {
    return (
        <svg onMouseOver={onMouseOver} style={style} xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" className={className}>
            <path d="M1 1.5L5.29289 5.79289C5.62623 6.12623 5.79289 6.29289 6 6.29289C6.20711 6.29289 6.37377 6.12623 6.70711 5.79289L11 1.5" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default ArrowBlack