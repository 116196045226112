import React from 'react'

const DashboardBlue = ({ className, iconColor = "4A6DA7" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className={className}>
            <g clip-path="url(#clip0_3702_1209)">
                <path d="M14.8333 11.6667C14.8333 12.6001 14.8333 13.0668 14.6517 13.4233C14.4919 13.7369 14.2369 13.9919 13.9233 14.1517C13.5668 14.3333 13.1001 14.3333 12.1667 14.3333C11.2332 14.3333 10.7665 14.3333 10.41 14.1517C10.0964 13.9919 9.84144 13.7369 9.68166 13.4233C9.5 13.0668 9.5 12.6001 9.5 11.6667C9.5 10.7332 9.5 10.2665 9.68166 9.91002C9.84145 9.59641 10.0964 9.34144 10.41 9.18166C10.7665 9 11.2332 9 12.1667 9C13.1001 9 13.5668 9 13.9233 9.18166C14.2369 9.34145 14.4919 9.59641 14.6517 9.91002C14.8333 10.2665 14.8333 10.7332 14.8333 11.6667Z" stroke={iconColor} stroke-width="1.25" />
                <path d="M14.8333 6.33333C14.8333 7.26675 14.8333 7.73346 14.6517 8.08998C14.4919 8.40358 14.2369 8.65855 13.9233 8.81834C13.5668 9 13.1001 9 12.1667 9C11.2332 9 10.7665 9 10.41 8.81834C10.0964 8.65855 9.84144 8.40358 9.68166 8.08998C9.5 7.73346 9.5 7.26675 9.5 6.33333C9.5 5.39991 9.5 4.9332 9.68166 4.57668C9.84145 4.26308 10.0964 4.00811 10.41 3.84832C10.7665 3.66666 11.2332 3.66666 12.1667 3.66666C13.1001 3.66666 13.5668 3.66666 13.9233 3.84832C14.2369 4.00811 14.4919 4.26308 14.6517 4.57668C14.8333 4.9332 14.8333 5.39991 14.8333 6.33333Z" stroke={iconColor} stroke-width="1.25" />
                <path d="M9.49935 11.6667C9.49935 12.6001 9.49935 13.0668 9.31769 13.4233C9.1579 13.7369 8.90294 13.9919 8.58933 14.1517C8.23281 14.3333 7.7661 14.3333 6.83268 14.3333C5.89926 14.3333 5.43255 14.3333 5.07603 14.1517C4.76243 13.9919 4.50746 13.7369 4.34767 13.4233C4.16602 13.0668 4.16602 12.6001 4.16602 11.6667C4.16602 10.7332 4.16602 10.2665 4.34767 9.91002C4.50746 9.59641 4.76243 9.34144 5.07603 9.18166C5.43255 9 5.89926 9 6.83268 9C7.7661 9 8.23281 9 8.58933 9.18166C8.90294 9.34145 9.15791 9.59641 9.31769 9.91002C9.49935 10.2665 9.49935 10.7332 9.49935 11.6667Z" stroke={iconColor} stroke-width="1.25" />
                <path d="M6.83333 3.66667C6.83333 4.60009 6.83333 5.0668 6.65168 5.42332C6.49189 5.73692 6.23692 5.99189 5.92332 6.15168C5.5668 6.33333 5.10009 6.33333 4.16667 6.33333C3.23325 6.33333 2.76654 6.33333 2.41002 6.15168C2.09641 5.99189 1.84144 5.73692 1.68166 5.42332C1.5 5.0668 1.5 4.60009 1.5 3.66667C1.5 2.73325 1.5 2.26653 1.68166 1.91002C1.84145 1.59641 2.09641 1.34144 2.41002 1.18166C2.76654 1 3.23325 1 4.16667 1C5.10009 1 5.5668 1 5.92332 1.18166C6.23692 1.34145 6.49189 1.59641 6.65168 1.91002C6.83333 2.26654 6.83333 2.73325 6.83333 3.66667Z" stroke={iconColor} stroke-width="1.25" />
            </g>
            <defs>
                <clipPath id="clip0_3702_1209">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DashboardBlue