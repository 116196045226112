export const CalenderLangData = {
  da: {
    name: "da",
    months: [
      ["Januar", "Jan"],
      ["Februar", "Feb"],
      ["Marts", "Mar"],
      ["April", "Apr"],
      ["Maj", "Maj"],
      ["Juni", "Jun"],
      ["Juli", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["Oktober", "Okt"],
      ["November", "Nov"],
      ["December", "Dec"],
    ],
    weekDays: [
      ["Lørdag", "Lø"],
      ["Søndag", "Sø"],
      ["Mandag", "Ma"],
      ["Tirsdag", "Ti"],
      ["Onsdag", "On"],
      ["Torsdag", "To"],
      ["Fredag", "Fr"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["Før Middag", "AM"],
      ["Efter Middag", "PM"],
    ],
  },
  de: {
    name: "de",
    months: [
      ["Januar", "Jan"],
      ["Februar", "Feb"],
      ["März", "Mär"],
      ["April", "Apr"],
      ["Mai", "Mai"],
      ["Juni", "Jun"],
      ["Juli", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["Oktober", "Okt"],
      ["November", "Nov"],
      ["Dezember", "Dez"],
    ],
    weekDays: [
      ["Samstag", "Sa"],
      ["Sonntag", "So"],
      ["Montag", "Mo"],
      ["Dienstag", "Di"],
      ["Mittwoch", "Mi"],
      ["Donnerstag", "Do"],
      ["Freitag", "Fr"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["Vormittag", "AM"],
      ["Nachmittag", "PM"],
    ],
  },
  en: {
    name: "en",
    months: [
      ["January", "Jan"],
      ["February", "Feb"],
      ["March", "Mar"],
      ["April", "Apr"],
      ["May", "May"],
      ["June", "Jun"],
      ["July", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["October", "Oct"], // Corrected "Okt" to "Oct" in English
      ["November", "Nov"],
      ["December", "Dec"],
    ],
    weekDays: [
      ["Saturday", "Sat"],
      ["Sunday", "Sun"],
      ["Monday", "Mon"],
      ["Tuesday", "Tue"],
      ["Wednesday", "Wed"],
      ["Thursday", "Thu"],
      ["Friday", "Fri"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["AM", "AM"],
      ["PM", "PM"],
    ],
  },
  no: {
    name: "no",
    months: [
      ["Januar", "Jan"],
      ["Februar", "Feb"],
      ["Mars", "Mar"],
      ["April", "Apr"],
      ["Mai", "Mai"],
      ["Juni", "Jun"],
      ["Juli", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["Oktober", "Okt"],
      ["November", "Nov"],
      ["Desember", "Des"],
    ],
    weekDays: [
      ["Lørdag", "Lør"],
      ["Søndag", "Søn"],
      ["Mandag", "Man"],
      ["Tirsdag", "Tir"],
      ["Onsdag", "Ons"],
      ["Torsdag", "Tor"],
      ["Fredag", "Fre"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["Før Middag", "AM"],
      ["Etter Middag", "PM"],
    ],
  },
  sv: {
    name: "sv",
    months: [
      ["Januari", "Jan"],
      ["Februari", "Feb"],
      ["Mars", "Mar"],
      ["April", "Apr"],
      ["Maj", "Maj"],
      ["Juni", "Jun"],
      ["Juli", "Jul"],
      ["Augusti", "Aug"],
      ["September", "Sep"],
      ["Oktober", "Okt"],
      ["November", "Nov"],
      ["December", "Dec"],
    ],
    weekDays: [
      ["Lördag", "Lör"],
      ["Söndag", "Sön"],
      ["Måndag", "Mån"],
      ["Tisdag", "Tis"],
      ["Onsdag", "Ons"],
      ["Torsdag", "Tor"],
      ["Fredag", "Fre"],
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [
      ["Förmiddag", "FM"],
      ["Eftermiddag", "EM"],
    ],
  },
};

// export const CalenderLangData = {
//   da: {
//     name: "da",
//     months: [
//       ["Januar", "Jan"],
//       ["Februar", "Feb"],
//       ["Marts", "Mar"],
//       ["April", "Apr"],
//       ["Maj", "Maj"],
//       ["Juni", "Jun"],
//       ["Juli", "Jul"],
//       ["August", "Aug"],
//       ["September", "Sep"],
//       ["Oktober", "Okt"],
//       ["November", "Nov"],
//       ["December", "Dec"],
//     ],
//     weekDays: [
//       ["Lørdag", "Lør"],
//       ["Søndag", "Søn"],
//       ["Mandag", "Man"],
//       ["Tirsdag", "Tir"],
//       ["Onsdag", "Ons"],
//       ["Torsdag", "Tor"],
//       ["Fredag", "Fre"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//       ["Før Middag", "AM"],
//       ["Efter Middag", "PM"],
//     ],
//   },
//   en: {
//     name: "en",
//     months: [
//       ["January", "Jan"],
//       ["February", "Feb"],
//       ["March", "Mar"],
//       ["April", "Apr"],
//       ["May", "May"],
//       ["June", "Jun"],
//       ["July", "Jul"],
//       ["August", "Aug"],
//       ["September", "Sep"],
//       ["October", "Okt"],
//       ["November", "Nov"],
//       ["December", "Dec"],
//     ],
//     weekDays: [
//       ["Saturday", "Sat"],
//       ["Sunday", "Sun"],
//       ["Monday", "Mon"],
//       ["Tuesday", "Tue"],
//       ["Wednesday", "Wed"],
//       ["Thursday", "Thu"],
//       ["Friday", "Fri"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//       ["AM", "AM"],
//       ["PM", "PM"],
//     ],
//   },
//   no: {
//     name: "no",
//     months: [
//       ["Januar", "Jan"],
//       ["Februar", "Feb"],
//       ["Mars", "Mar"],
//       ["April", "Apr"],
//       ["Mai", "Mai"],
//       ["Juni", "Jun"],
//       ["Juli", "Jul"],
//       ["August", "Aug"],
//       ["September", "Sep"],
//       ["Oktober", "Okt"],
//       ["November", "Nov"],
//       ["Desember", "Des"],
//     ],
//     weekDays: [
//       ["Lørdag", "Lør"],
//       ["Søndag", "Søn"],
//       ["Mandag", "Man"],
//       ["Tirsdag", "Tir"],
//       ["Onsdag", "Ons"],
//       ["Torsdag", "Tor"],
//       ["Fredag", "Fre"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//       ["Før Middag", "AM"],
//       ["Etter Middag", "PM"],
//     ],
//   },
//   tr: {
//     name: "tr",
//     months: [
//       ["Ocak", "Oca"],
//       ["Şubat", "Şub"],
//       ["Mart", "Mar"],
//       ["Nisan", "Nis"],
//       ["Mayıs", "May"],
//       ["Haziran", "Haz"],
//       ["Temmuz", "Tem"],
//       ["Ağustos", "Ağu"],
//       ["Eylül", "Eyl"],
//       ["Ekim", "Eki"],
//       ["Kasım", "Kas"],
//       ["Aralık", "Ara"],
//     ],
//     weekDays: [
//       ["Cumartesi", "Cum"],
//       ["Pazar", "Paz"],
//       ["Pazartesi", "Pts"],
//       ["Salı", "Sal"],
//       ["Çarşamba", "Çar"],
//       ["Perşembe", "Per"],
//       ["Cuma", "Cum"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//       ["Öğle Öncesi", "AM"],
//       ["Öğle Sonrası", "PM"],
//     ],
//   },
//   sv: {
//     name: "sv",
//     months: [
//       ["Januari", "Jan"],
//       ["Februari", "Feb"],
//       ["Mars", "Mar"],
//       ["April", "Apr"],
//       ["Maj", "Maj"],
//       ["Juni", "Jun"],
//       ["Juli", "Jul"],
//       ["Augusti", "Aug"],
//       ["September", "Sep"],
//       ["Oktober", "Okt"],
//       ["November", "Nov"],
//       ["December", "Dec"],
//     ],
//     weekDays: [
//       ["Lördag", "Lör"],
//       ["Söndag", "Sön"],
//       ["Måndag", "Mån"],
//       ["Tisdag", "Tis"],
//       ["Onsdag", "Ons"],
//       ["Torsdag", "Tor"],
//       ["Fredag", "Fre"],
//     ],
//     digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
//     meridiems: [
//       ["Förmiddag", "FM"],
//       ["Eftermiddag", "EM"],
//     ],
//   },
// };