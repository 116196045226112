import React from 'react'

const PhoneOutline = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none" className={className}>
            <path d="M3.37017 15.0011C2.10613 12.797 1.4958 10.9973 1.12778 9.17291C0.583497 6.47473 1.82908 3.83904 3.89251 2.15726C4.7646 1.44647 5.76431 1.68932 6.28 2.61449L7.44425 4.70317C8.36705 6.35872 8.82846 7.18649 8.73694 8.06408C8.64542 8.94168 8.02316 9.65644 6.77863 11.086L3.37017 15.0011ZM3.37017 15.0011C5.92867 19.4623 9.94378 23.4796 14.4102 26.0411M14.4102 26.0411C16.6143 27.3052 18.414 27.9155 20.2384 28.2835C22.9366 28.8278 25.5723 27.5822 27.254 25.5188C27.9648 24.6467 27.722 23.647 26.7968 23.1313L24.7081 21.9671C23.0526 21.0442 22.2248 20.5828 21.3472 20.6744C20.4696 20.7659 19.7549 21.3881 18.3253 22.6327L14.4102 26.0411Z" stroke="#333333" stroke-width="2" stroke-linejoin="round" />
        </svg>
    )
}

export default PhoneOutline