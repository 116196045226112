import React, { useState } from "react";
import "./LoginModel.css";
import MailIcon from "../../../assets/Icons/mail__outline";
import LockBlack from "../../../assets/Icons/lock__black";
import CrossIcon from "../../../assets/Icons/crossIcon.png";
import Input from "../input/Input";
import { useTranslation } from "react-i18next";
import DZText from "../../text/DZText";
import useValidationFunction from "../../../hooks/useValidationFunction";
import { useSnackBarManager } from "../../../hooks/useSnackBarManager";
import { useLoginUserMutation } from "../../../redux/storeApis";
import { Config } from "../../../constant/Index";
import { setLocalStorage } from "../local_storage";
import { Route, useNavigate } from "react-router-dom";
import RoutesLink from "../../../constant/RoutesLink";
const LoginModel = ({ loginModel, setLoginModel, setForgotModal }) => {

  const navigate = useNavigate();

  const [loginUser, { isLoading }] = useLoginUserMutation();
  const { fnShowSnackBar } = useSnackBarManager();
  const { t } = useTranslation();
  const { validateData, getFirstErrorMessage, isEmpty } = useValidationFunction();
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setLoginModel(false);
  };
  const handleLogin = async () => {
    const newError = validateData(loginData);
    setErrors(newError);
    if (!isEmpty(newError)) {
      fnShowSnackBar(getFirstErrorMessage(newError), true);
      return;
    }

    try {
      const response = await loginUser(loginData).unwrap();
      if (response.message === "successfully") {
        const fetchUserToken = response?.data?.access_token;
        if (fetchUserToken) {
          setLocalStorage(Config.userToken, fetchUserToken);
          fnShowSnackBar("User successfully logged in!");
          navigate(RoutesLink.LEADS_PAGE);
          // window.location.reload();
          setLoginModel(false);
        } else {
          fnShowSnackBar("User login Failed", true);
        }
      } else {
        fnShowSnackBar("Invalid Credentials", true);
      }
    } catch (error) {
      console.error("Login failed:", error);
      fnShowSnackBar("some error occur");
    }
  };
  return (
    <>
      {!loginModel ? null : (
        <span className={` model__back ${loginModel ? "" : "d__none"}`}></span>
      )}
      {!loginModel ? null : (
        <div className={` login__model ${loginModel ? "" : "d__none"}`}>
          <img
            src={CrossIcon}
            className="cursor__p position__abs r__20 t__20"
            onClick={handleClose}
            alt=""
          />
          <div className="d__flex flex__col gap__12 align__center w__full">
            <h2 className="model__heading">{t("login_model.heading")}</h2>
            <p className="poppins__16">{t("login_model.sub_heading")}</p>
          </div>
          <div className="d__flex flex__col gap__16 align__center w__full">
            <Input
              icon={<MailIcon className="w__24 h__24" />}
              value={loginData.email}
              placeholder={t("login_model.email")}
              onChange={handleChange}
              type="email"
              name="email"
              required={true}
              error={errors.email}
            />
            <Input
              icon={<LockBlack className="w__24 h__24" />}
              value={loginData.password}
              placeholder={t("login_model.password")}
              onChange={handleChange}
              type="password"
              name="password"
              required={true}
              error={errors.password}
            />
          </div>
          <div className="d__flex gap__10 align__center w__full">
            <input
              id="abcd"
              type="checkbox"
              className="cursor__p w__16 h__16"
            />
            <label htmlFor="abcd" className="poppins__16 cursor__p">
              {t("login_model.remember_me")}
            </label>
          </div>
          <div className="d__flex flex__col gap__16 align__center w__full">
            <DZText
              onClick={handleLogin}
              className={
                "cursor__p w__full md-fs__15 back__primary__100 opacity__white__100 pl__32 pr__32 pb__12 pt__12 radi__24 min__w__200 text__center sm-min__w__full"
              }
            >
              {isLoading? "loading...":"login_model.sign_in"}
            </DZText>
            <DZText
              onClick={()=> { setLoginModel(false); setForgotModal(true)}}
              className={
                "cursor__p w__full md-fs__15  solid__black__80 pl__32 pr__32 pb__12 pt__12 radi__24 b__1__white100 min__w__200 text__center sm-min__w__full"
              }
            >
              {"login_model.forgot_password"}
            </DZText>
          </div>
        </div>
      )}
    </>
  );
};
export default LoginModel;
