import React from "react";

const phone__blue = ({ className, iconColor = "#4A6DA7" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      className={className}
    >
      <path
        d="M4.03618 14.9231C2.77215 12.7191 2.16181 10.9193 1.7938 9.09494C1.24951 6.39676 2.4951 3.76107 4.55852 2.07929C5.43061 1.3685 6.43032 1.61135 6.94602 2.53652L8.11026 4.6252C9.03307 6.28074 9.49447 7.10851 9.40295 7.98611C9.31144 8.86371 8.68917 9.57847 7.44465 11.008L4.03618 14.9231ZM4.03618 14.9231C6.59469 19.3844 10.6098 23.4017 15.0762 25.9632M15.0762 25.9632C17.2803 27.2272 19.08 27.8375 20.9044 28.2055C23.6026 28.7498 26.2383 27.5042 27.9201 25.4408C28.6308 24.5687 28.388 23.569 27.4628 23.0533L25.3741 21.8891C23.7186 20.9663 22.8908 20.5049 22.0132 20.5964C21.1356 20.6879 20.4209 21.3102 18.9913 22.5547L15.0762 25.9632Z"
        stroke={iconColor}
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default phone__blue;
