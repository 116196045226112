import React from "react";
import Loader from "../../../assets/Loader";
import useLoaderShow from "../../../hooks/useLoaderShow";
import "./loaderShow.css"
function LoaderShow({className}) {
    const { isLoading, show, hide, toggle } = useLoaderShow();
  return (
    <>
      {isLoading ? (
        <div className={ className ?? "loader__div"}>
          <Loader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default LoaderShow;